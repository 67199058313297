import { ReactNode } from 'react'
import { SWRConfig } from 'swr'

interface Props {
  children: ReactNode
  fallback?: Record<string, unknown>
}

const SWRProvider = ({ children, fallback }: Props) => (
  <SWRConfig
    value={{
      fetcher: (resource, init) =>
        fetch(resource, init).then((res) => res.json()),
      fallback,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
  >
    {children}
  </SWRConfig>
)

export default SWRProvider
