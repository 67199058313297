import notEmpty from '../utils/notEmpty'
import slugify from '../utils/slugify'
import { PageStoryblok, PortfolioStoryblok } from './storyblok.generated'

const convertBloksToPatterns = (
  bloks: PortfolioStoryblok['content'] | PageStoryblok['content'],
) =>
  bloks
    .flatMap((blok) => {
      switch (blok.component) {
        default:
          return 'sectionTitle' in blok && blok.sectionTitle
            ? {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                id: slugify(blok.sectionTitle)!,
                label: blok.sectionTitle,
              }
            : undefined
      }
    })
    .filter(notEmpty)

export default convertBloksToPatterns
