import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, memo } from 'react'

import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import squarePolygon from '../utils/squarePolygon'
import SectionIndicator from './SectionIndicator'

/**
 * This component must be placed in a relative positioned container to work
 * properly.
 */

// This component matches its parent's size in order to clip the section
// indicator so it can change colors based on the background color of a section.
const SectionIndicatorClipper = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    clip-path: ${squarePolygon()};
    pointer-events: none;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const FixedSectionIndicator = styled(SectionIndicator)(
  () => css`
    pointer-events: all;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    // In case a parent component decides to center align, we can fix this easily in one swoop
    text-align: left;
  `,
)

type Props = ComponentProps<typeof FixedSectionIndicator>

const isHoveringSectionIndicatorVar = makeVar(false)

const SectionIndicatorClipped = (props: Props) => {
  const isHoveringSectionIndicator = useReactiveVar(
    isHoveringSectionIndicatorVar,
  )

  const handleMouseEnter = () => {
    isHoveringSectionIndicatorVar(true)
  }
  const handleMouseLeave = () => {
    isHoveringSectionIndicatorVar(false)
  }

  return (
    <SectionIndicatorClipper>
      <FixedSectionIndicator
        {...props}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        expanded={isHoveringSectionIndicator}
      />
    </SectionIndicatorClipper>
  )
}

export default memo(SectionIndicatorClipped)
